import { useEffect, useState } from 'react';
import './App.css';

function App() {

  const { AP } = window;
  const [base64, setBase64] = useState(undefined)

  useEffect(() => {
    AP.confluence.getMacroData(function(data) {
      if (!!data?.data) setBase64(data.data)
    })
  }, [AP?.confluence])

  if (!base64) return <p>Loading...</p>

  return (
    <div style={{ width: '100%', textAlign: 'center' }} >
      <img src={base64} alt="Excalidraw Rendered" />
    </div>
  )
}

export default App;
